<template>
  <div class="error-img">
    <cn-header v-if="host.indexOf('cn')!==-1"></cn-header>
    <en-header v-else></en-header>
    <div class="error-content">
        <div class="download-header"></div>
        <img :src="$http+'/img/404.jpg'" alt="">
    </div>
    <cn-footer v-if="host.indexOf('cn')!==-1"></cn-footer>
    <en-footer v-else></en-footer>
  </div>
</template>

<script>
import cnFooter from "../../components/zh/commonFooter.vue";
import cnHeader from "../../components/zh/commonHeader.vue";
import enFooter from "../../components/en/commonFooter.vue";
import enHeader from "../../components/en/commonHeader.vue";
export default {
    components:{
        cnFooter,
        cnHeader,
        enFooter,
        enHeader
    },
    data(){
        return{
            host: ''
        }
    },
    mounted(){
        this.host = window.location.host
    }
}
</script>

<style scoped lang='scss'>
.error-img{
    position: relative;
    width: 100%;
    height: 100vh;
    padding-bottom: 336px;
    .error-content{
        padding: 90px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 800px;
        img{
            width: 706px;
            height: 574px;
        }
    }
    
    .download-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 90px;
        background: #000;
    }
    .common-footer{
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
</style>